import React from "react";

const Pinterest = ({
  viewBox = "0 0 24 24",
  color = "#333",
  size = 24,
  ...props
}) => (
  <svg
    viewBox="0 0 1024 1024"
    p-id="14117"
    width={size}
    height={size}
    {...props}
  >
    <path
      d="M748.311273 421.701818c0-106.170182-88.110545-205.079273-221.975273-205.079273-166.632727 0-250.600727 121.949091-250.600727 223.65091 0 61.579636 22.807273 116.363636 71.819636 136.750545 8.005818 3.351273 15.220364 0.093091 17.547636-8.936727 1.582545-6.283636 5.445818-22.155636 7.121455-28.718546 2.373818-8.983273 1.442909-12.101818-5.026909-19.968-14.149818-17.082182-23.133091-39.051636-23.133091-70.237091 0-90.437818 66.280727-171.426909 172.637091-171.426909 94.161455 0 145.92 58.740364 145.92 137.169455 0 103.237818-44.730182 190.324364-111.197091 190.324363-36.724364 0-64.139636-30.952727-55.342545-68.933818 10.519273-45.335273 30.952727-94.301091 30.952727-127.022545 0-29.277091-15.406545-53.76-47.290182-53.76-37.515636 0-67.630545 39.610182-67.630545 92.672 0 33.792 11.217455 56.645818 11.217454 56.645818l-45.102545 195.072c-6.097455 26.484364-7.028364 55.575273-6.190546 80.151273 2.56 22.062545 23.924364 40.029091 42.449455 15.825454 13.032727-21.271273 27.042909-48.128 34.304-75.031272 4.328727-15.918545 24.669091-98.397091 24.669091-98.397091 12.194909 23.738182 47.802182 44.637091 85.690181 44.637091 112.686545-0.046545 189.160727-104.96 189.160728-245.387637z"
     fill={color}
      p-id="14118"
    ></path>
    <path
      d="M1024 512c0-282.763636-229.236364-512-512-512S0 229.236364 0 512s229.236364 512 512 512 512-229.236364 512-512zM46.545455 512C46.545455 254.929455 254.929455 46.545455 512 46.545455s465.454545 208.384 465.454545 465.454545-208.384 465.454545-465.454545 465.454545S46.545455 769.070545 46.545455 512z"
      fill={color}
      p-id="14119"
    ></path>
  </svg>
);

export default Pinterest;
