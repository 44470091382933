import React, { Component } from "react";
import HeaderBar from "./common/HeaderBar";
import FooterBar from "./common/FooterBar";
import guarantee from "../image/usp-guarantee.svg";
import "../style/privacy.scss";

class Privacy extends Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    window.scrollTo(0, 0);
  }


  render() {
    return (
      <React.Fragment>
        <HeaderBar {...this.props} />
        <div className="privacy">
          <h2>PRIVACY & COOKIES</h2>
          <p>
            Your data is in safe hands. We’ll always keep your information safe
            and secure. Below you can find out why we need your data and how we
            use it.
          </p>
          <p>
            <b>Our Privacy Promise</b>
          </p>
          <p>1. We’ll protect your data like it’s our own</p>
          <p>2. We’ll only use your data to improve your experience</p>
          <p>
            3. We’ll never send you spam. You decide what and how you hear from
            us
          </p>
          <p>
            4. We won’t hang on to your data needlessly. If we no longer need
            it, we’ll delete it
          </p>
          <p>
            <b>Protecting Your Privacy</b>
          </p>
          <p>
            StressFreePrint is committed to protecting the privacy and security
            of our customers and site visitors. If you have any questions about
            how we protect your privacy, you can contact us at{" "}
            <span>dataprotection@stressfreeprint.co.uk</span>
          </p>
          <p>
            For all our services, the data controller - the company that’s
            responsible for your privacy - is Asterisk Creative Consultants Ltd
            t/a StressFreePrint.
          </p>
          <p>
            <b>How we use your information</b>
          </p>
          <p>
            We use your information in a number of different ways. Below, we
            describe what we do and why we do it.
          </p>
          <p>
            <b>How to contact us</b>
          </p>
          <p>
            We’re always keen to hear from our customers, especially if you feel
            we’ve let you down or could do better.
          </p>
          <p>
            If you have any questions or feedback about this notice; would like
            us to stop using your information; want to exercise any of your
            rights as set out above; or have a complaint; please don’t hesitate
            to contact us. We will be happy to answer any questions you may
            have.{" "}
          </p>
          <p>
            You can contact us at{" "}
            <span> dataprotection@stressfreeprint.co.uk.</span> Alternatively,
            you can write to us at:
          </p>
          <p>
            Data Protection <br /> StressFreePrint <br /> Unit 12 Lodge Bank{" "}
            <br />
            Crown Lane <br />
            Bolton <br /> BL6 5HY
          </p>
          <p>
            UPDATE VERSION 2.1 <br />
            UPDATE DATE 24/05/2018
          </p>
          <div className="banner1">
            <div className="img">
              <img src={guarantee} alt="" />
            </div>
            <p>
              Everything we do is covered by our{" "}
              <span>100% StressFree Guarantee.</span>
            </p>
            <p>
              If you're not completely satisfied with your purchase, we'll
              reprint or refund your order. No fuss, no faff.
            </p>
          </div>
        </div>
        <FooterBar {...this.props} />
      </React.Fragment>
    );
  }
}

export default Privacy;
