import React, { Component } from "react";
import "../style/home.scss";
import { Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/scss";
import "swiper/scss/navigation";
import "swiper/scss/pagination";
import Package from "../icon/Package";
import Percent from "../icon/Percent";
import Medal from "../icon/Medal";
import Return from "../icon/Return";
import { Select } from "antd";
import { productList } from "../apis/GetProduct";
import HeaderBar from "./common/HeaderBar";
import banner from "../image/banner.jpg";
import banner1 from "../image/banner2.jpg";
import BannerBar from "./common/BannerBar";
import FooterBar from "./common/FooterBar";
import guarantee from "../image/usp-guarantee.svg";

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      product: [],
    };
    this.mouseEnter = this.mouseEnter.bind(this);
    this.mouseLeave = this.mouseLeave.bind(this);
    this.routeProductDetail = this.routeProductDetail.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.setState({ product: productList });
  }

  mouseEnter(e, item) {
    e.target.src = item.image[1];
  }
  mouseLeave(e, item) {
    e.target.src = item.image[0];
  }

  routeProductDetail(id) {
    let { history } = this.props;
    history.push(`/ProductDetail/${id}`);
  }

  render() {
    const { product } = this.state;
    console.log(product);
    return (
      <React.Fragment>
        <HeaderBar {...this.props} />
        <div className="banner">
          <img src={banner} alt="" />
          <div className="banner-box">
            <div className="title">Bespoke print projects</div>
            <div className="title">... as easy as pie</div>
          </div>
        </div>
        <BannerBar {...this.props} />
        {/* product */}
        <div className="product-row">
          {product &&
            product.map((item, index) => {
              return (
                <div
                  className="col"
                  key={index}
                  onClick={() => this.routeProductDetail(item.id)}
                >
                  <div className="img-box">
                    <img
                      src={item.image[0]}
                      alt=""
                      onMouseEnter={(e) => this.mouseEnter(e, item)}
                      onMouseLeave={(e) => this.mouseLeave(e, item)}
                    />
                  </div>
                  <div className="title">{item.title}</div>
                  <div className="shop">SHOP NOW {">"}</div>
                </div>
              );
            })}
        </div>

        <div className="banner1">
          <div className="img">
            <img src={guarantee} alt="" />
          </div>
          <p>
            Everything we do is covered by our{" "}
            <span>100% StressFree Guarantee.</span>
          </p>
          <p>
            If you're not completely satisfied with your purchase, we'll reprint
            or refund your order. No fuss, no faff.
          </p>
        </div>
        <div className="banner2">
          <div className="title">ONLINE PRINTING BY STRESSFREEPRINT</div>
          <p>
            Welcome to StressFreePrint, your hassle-free solution for online
            printing! Since 2006, we've been on a mission to make ordering print
            a stress-free experience. But we don't stop at <span>leaflets</span>{" "}
            , <span>flyers</span>, <span>posters</span> and{" "}
            <span>brochures</span>. We've expanded our offering to include a
            huge range of custom printed products, everything from{" "}
            <span>stationery</span> to
            <span>signage</span>, <span>exhibition graphics</span> to{" "}
            <span>bespoke packaging</span>.
          </p>
          <p>
            As a leading UK online printing company, we measure our success by
            the quality of our products, the service we deliver and the way we
            make our customers feel. That's why we use only the very best
            materials and take the time to oversee each little detail of your
            order. It's not the easiest approach, but it's your guarantee that
            you'll be over the moon with our printing services.
          </p>
          <p>
            On the flip side of this website you’ll find a dedicated team of
            print geeks... ready, eager and willing to turn your ideas into a
            printed reality. Need something unique? We love a challenge! Enquire
            about our <span>tailored solutions</span> today.
          </p>
          <p>
            Let us handle your printing needs while you focus on what matters
            most - growing your business. Ready to unlock your brand's
            potential? Join our community of satisfied customers and experience
            the StressFreePrint difference for yourself.{" "}
          </p>
        </div>
        <div className="banner3">
          <img src={banner1} alt="" />
          <div className="contain">
            <div className="box">
              <div className="box-content">
                <div className="contain-box">
                  <div className="title">PRINT ONE {">"} PLANT ONE</div>
                  <div className="p">
                    <i>
                      Find out how your print helps tackle <br /> deforestation,
                      poverty and climate change
                    </i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <FooterBar {...this.props} />
      </React.Fragment>
    );
  }
}

export default Home;
