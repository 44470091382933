import React, { Component } from "react";
import HeaderBar from "./common/HeaderBar";
import FooterBar from "./common/FooterBar";
import "../style/guarantee.scss";
import guarantee from "../image/usp-guarantee.svg";

class Guarantee extends Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <React.Fragment>
        <HeaderBar {...this.props} />
        <div className="guarantee">
          <div className="img">
            <img src={guarantee} alt="" />
          </div>
          <h2>100% Satisfaction Guarantee</h2>
          <p>
            At StressFreePrint, we pride ourselves on the quality and care we
            pour into every single order. That's
            <br />
            why everything we do is underpinned by our{" "}
            <b>100% Satisfaction Guarantee</b>.
          </p>
          <h2>Our Pledge</h2>
          <p>
            If you're not wholly satisfied with your purchase for any reason,
            we're here to set things right:
          </p>
          <p>
            <b>Reprint</b>: We won't rest until it's right. We'll redo your
            order to ensure it's just as you envisioned.
          </p>
          <p>
            <b>Refund</b>: Prefer to get your money back? No problem, we'll sort
            it.
          </p>
          <p>
            It's straightforward: No fuss, no faff, just our commitment to your
            satisfaction.
          </p>
          <p>
            This guarantee is in addition to, and does not affect, your
            statutory rights and is subject to our standard terms and
            conditions.
          </p>
          <div className="banner1">
            <div className="img">
              <img src={guarantee} alt="" />
            </div>
            <p>
              Everything we do is covered by our{" "}
              <span>100% StressFree Guarantee.</span>
            </p>
            <p>
              If you're not completely satisfied with your purchase, we'll
              reprint or refund your order. No fuss, no faff.
            </p>
          </div>
        </div>
        <FooterBar {...this.props} />
      </React.Fragment>
    );
  }
}

export default Guarantee;
