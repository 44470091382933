import React from "react";

const HalfStar = ({
  viewBox = "0 0 24 24",
  size = 24,
  color = "#f4ea2a",
  ...props
}) => (
  <svg
    viewBox="0 0 1025 1024"
    p-id="5537"
    width={size}
    height={size}
    {...props}
  >
    <path
      d="M1020.53613 415.3c9.5-9.3 4.3-25.4-8.9-27.3l-326.5-47.5c-5.2-0.8-9.7-4-12-8.8l-146-295.9c-2.9-5.9-8.6-8.9-14.3-8.9s-11.4 3-14.3 8.9l-146 295.9c-2.3 4.7-6.8 8-12 8.8L13.73613 388c-13.1 1.9-18.4 18-8.9 27.3l236.3 230.3c3.8 3.7 5.5 9 4.6 14.2L190.03613 985c-1.8 10.3 6.4 18.7 15.8 18.7 2.5 0 5-0.6 7.5-1.9l292.1-153.5c2.3-1.2 4.9-1.8 7.5-1.8s5.1 0.6 7.5 1.8l292.1 153.5c2.5 1.3 5 1.9 7.5 1.9 9.3 0 17.5-8.4 15.8-18.7L779.73613 659.8c-0.9-5.2 0.8-10.5 4.6-14.2l236.2-230.3zM716.63613 670.6L755.93613 900l-206-108.3c-1.7-0.9-3.5-1.7-5.2-2.5V216.3l71 143.8c11.7 23.6 34.2 40 60.2 43.8l230.3 33.5-166.6 162.4c-18.9 18.3-27.5 44.8-23 70.8z"
      p-id="5538"
      fill={color}
    ></path>
  </svg>
);

export default HalfStar;
