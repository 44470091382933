import React from "react";

const Fackbook = ({
  viewBox = "0 0 24 24",
  size = 24,
  color = "#fff",
  ...props
}) => (
  <svg
    viewBox="0 0 1024 1024"
    p-id="3349"
    width={size}
    height={size}
    {...props}
  >
    <path
      d="M1024 512c0-282.763636-229.236364-512-512-512C229.236364 0 0 229.236364 0 512s229.236364 512 512 512C794.763636 1024 1024 794.763636 1024 512zM46.545455 512C46.545455 254.929455 254.929455 46.545455 512 46.545455c257.070545 0 465.454545 208.384 465.454545 465.454545 0 257.070545-208.384 465.454545-465.454545 465.454545C254.929455 977.454545 46.545455 769.070545 46.545455 512z"
      p-id="3350"
      fill={color}
    ></path>
    <path
      d="M531.642182 807.517091 531.642182 512l97.512727 0 15.406545-97.931636-112.919273 0L531.642182 364.962909c0-25.553455 8.378182-49.943273 45.056-49.943273l73.309091 0 0-97.745455-104.075636 0c-87.505455 0-111.383273 57.623273-111.383273 137.495273l0 59.252364-60.043636 0L374.504727 512l60.043636 0 0 295.517091L531.642182 807.517091z"
      p-id="3351"
      fill={color}
    ></path>
  </svg>
);

export default Fackbook;
