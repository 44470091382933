import React, { Component } from "react";
import "../../style/common/footerBar.scss";
import carbon from "../../image/carbon_neutral_deliveries_logo.svg";
import plant from "../../image/print_one_plant_one.svg";
import woodland from "../../image/woodland.svg";
import banner2 from "../../image/keepintouch_1.jpg";
import Twtter from "../../icon/Twtter";
import Facebook from "../../icon/Facebook";
import Pinterest from "../../icon/Pinterest";

class FooterBar extends Component {
  constructor(props) {
    super(props);
    this.routePage = this.routePage.bind(this);
  }

  routePage(type) {
    let { history } = this.props;
    switch (type) {
      case "environment":
        history.push("/Environment");
        break;
      case "delivery":
        history.push("/Delivery");
        break;
      case "guarantee":
        history.push("/Guarantee");
        break;
      case "terms":
        history.push("/Terms");
        break;
      case "privacy":
        history.push("/Privacy");
        break;
      default:
        break;
    }
  }

  render() {
    return (
      <React.Fragment>
        <div className="banner4">
          <img src={banner2} alt="" />
          <div className="banner3-text">
            <div className="title">KEEP IN TOUCH</div>
            <div className="title2">
              9am - 5pm Monday - Friday | 0345 222 0345 |
              hello@stressfreeprint.co.uk
            </div>
            <div className="row">
              <Twtter size={45} color="#fff" className="icon" />{" "}
              <Facebook size={45} color="#fff" className="icon" />
              <Pinterest size={45} color="#fff" className="icon" />
            </div>
          </div>
        </div>
        <footer>
          <div className="content">
            <ul>
              <li onClick={() => this.routePage("environment")}>Environment</li>
              <li onClick={() => this.routePage("delivery")}>Delivery</li>
              <li onClick={() => this.routePage("guarantee")}>Guarantee</li>
              <li onClick={() => this.routePage("terms")}>Terms</li>
              <li onClick={() => this.routePage("privacy")}>Privacy</li>
            </ul>
            <div className="img-row">
              <div className="img">
                <img src={carbon} alt="" />
              </div>
              <div className="img">
                <img src={plant} alt="" />
              </div>
              <div className="img">
                <img src={woodland} alt="" />
              </div>
            </div>
          </div>
        </footer>
      </React.Fragment>
    );
  }
}

export default FooterBar;
