import React, { Component } from "react";
import HeaderBar from "./common/HeaderBar";
import FooterBar from "./common/FooterBar";
import "../style/delivery.scss";
import guarantee from "../image/usp-guarantee.svg";

class Delivery extends Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    window.scrollTo(0, 0);
  }


  render() {
    return (
      <React.Fragment>
        <HeaderBar {...this.props} />
        <div className="delivery">
          <h2>DELIVERY INFORMATION</h2>
          <p>
            <b>FREE DELIVERY</b>
          </p>
          <p>
            Delivery within mainland UK costs just £4.99 or it’s FREE with
            orders over £25.00 (excluding VAT). An additional delivery charge
            may apply to bespoke large format and signage products.
          </p>
          <p>
            <b>UNBRANDED & UNTRACEABLE</b>
          </p>
          <p>
            Not only are all our consignments unbranded, any delivery tracking
            links we provide are non-traceable too. So, if you're delivering
            direct to your customer, they'll never know we ever existed.
          </p>
          <p>
            <b>WHEN WILL I RECEIVE MY ORDER?</b>
          </p>
          <p>
            As our turnaround and delivery options differ from product to
            product, please refer to the relevant product page for further
            information. If your order includes multiple products, they may
            arrive in separate consignments.
          </p>
          <p>
            <b>EXTENDED AREA DELIVERIES</b>
          </p>
          <p>
            Our courier charges a little more for deliveries to the following UK
            offshore postcodes, the cost of which will be confirmed at checkout.
          </p>
          <p>
            <b>Please allow an additional day for delivery to these areas:</b>
          </p>
          <p>
            AB10 - AB16, AB21 - AB25, AB30 - AB39, AB41 - AB45, AB51 - AB56, DD8
            - DD11, EH43 - EH45, FK8, FK16 - FK21, IM, IV1 - IV17, IV19 - IV20,
            IV25, IV30 - IV36, IV63, KA4 - KA6, KA16 - KA26, KW1 - KW10, ML8 -
            ML12, PA20 - PA21, PA23 - PA28, PA32 - PA38, PH1 - PH35, PH39 -
            PH-40, PH49 - PH50, SY15 - SY25, TD2 - TD14, GY, JE, BT, PO30-39
          </p>
          <p>
            <b>Please allow 2 additional days for delivery to these areas:</b>
          </p>
          <p>PA22, PA29 - PA31, PA62 - PA72, PA74 - PA75, PA80, TR21 - TR25</p>
          <p>
            <b>Please allow 3 additional days for delivery to these areas:</b>
          </p>
          <p>
            IV21 - IV24, IV26 - IV28, IV40, IV52 - IV54, KW11 - KW14, PH36 -
            PH38, PH42 - PH44
          </p>
          <p>
            <b>OFFSHORE DELIVERIES</b>
          </p>
          <p>
            <b>Please allow 4 additional days for delivery to these areas:</b>
          </p>
          <p>
            HS1-9, IV41 - IV51, KA27 - KA28, KW15 - KW17, PA41 - PA49, PA60 -
            PA61, PA73, PA76 - PA78, ZE1 - ZE3
          </p>
          <p>
            We’re able to provide delivery to Europe and beyond. Please contact
            us for details.
          </p>
          <p>
            <b>FAILED DELIVERIES</b>
          </p>
          <p>
            If the delivery driver is unable to deliver your order, they will
            leave a card and re-attempt delivery the next working day.
          </p>
          <p>
            <b>ANY QUESTIONS?</b>
          </p>
          <p>
            If you have any questions, we're standing by to help. Please feel
            free to get in touch.
          </p>
          <div className="banner1">
            <div className="img">
              <img src={guarantee} alt="" />
            </div>
            <p>
              Everything we do is covered by our{" "}
              <span>100% StressFree Guarantee.</span>
            </p>
            <p>
              If you're not completely satisfied with your purchase, we'll
              reprint or refund your order. No fuss, no faff.
            </p>
          </div>
        </div>
        <FooterBar {...this.props} />
      </React.Fragment>
    );
  }
}

export default Delivery;
