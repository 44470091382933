import React, { Component } from "react";
import "../../style/common/bannerBar.scss";
import artwork from "../../image/usp-artwork.svg";
import bolton from "../../image/usp-bolton.svg";
import delivery from "../../image/usp-delivery.svg";
import guarantee from "../../image/usp-guarantee.svg";

class BannerBar extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <React.Fragment>
        <div className="bannerBar">
          <div className="banner-contain">
            <div className="li">
              <img src={artwork} alt="" />
              <p><span>Free</span> artwork check</p>
            </div>
            <div className="li">
              <img src={guarantee} alt="" />
              <p>100% satisfaction <span>guarantee</span></p>
            </div>
            <div className="li">
              <img src={bolton} alt="" className="bolton"/>
              <p>Printed <span>with pride</span> in the UK</p>
            </div>
            <div className="li">
              <img src={delivery} alt="" />
              <p><span>Fast</span> and free delivery*</p>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default BannerBar;
