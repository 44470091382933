import React, { Component } from "react";
import HeaderBar from "./common/HeaderBar";
import FooterBar from "./common/FooterBar";
import BannerBar from "./common/BannerBar";
import "../style/environment.scss";
import banner1 from "../image/greenheader.jpg";
import banner3 from "../image/green_sustainably_sourced_paper.jpg";
import banner4 from "../image/forrest-xpro.jpg";
import banner5 from "../image/landing_page_eden.jpg";
import banner6 from "../image/woodland-xpro.jpg";
import banner8 from "../image/eco_packaging.jpg";
import banner9 from "../image/zero_carbon_delivery.jpg";

class Environment extends Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <React.Fragment>
        <HeaderBar {...this.props} />
        <BannerBar {...this.props} />
        <div className="environment">
          <div className="banner">
            <img src={banner1} alt="" />
            <div className="banner-mask">FEEL GOOD PRINT</div>
          </div>
          <div className="banner2-row">
            <p>We're committed to reducing our impact on the environment.</p>
            <p>
              We're proud of what we've achieved and strive to do even better,
              giving
              <br />
              you the green light to feel good about your print.
            </p>
          </div>
          <div className="banner3-row">
            <div className="l">
              <img src={banner3} alt="" />
            </div>
            <div className="l">
              <h3>RESPONSIBLE FORESTRY</h3>
              <p>
                We only use paper which has been sustainably sourced. In other
                words, all our paper <br /> comes from forests that are managed
                to meet the needs of present and future <br />
                generations.
              </p>
              <p>
                When you order from us, you can be confident that you're
                supporting responsible <br /> forestry and a sustainable
                resource.
              </p>
            </div>
          </div>
          <div className="banner4-row">
            <div className="l">
              <h3>RECYCLED PAPER</h3>
              <p>
                It goes without saying that we recycle all our waste paper. We
                also offer a vast range <br />
                of products printed on recycled papers made from 100% consumer
                waste, available
                <br />
                in a choice of different weights.
              </p>
              <p>
                If you like to do your bit for the environment, browse our
                recycled range.
              </p>
            </div>
            <div className="l">
              <img src={banner4} alt="" />
            </div>
          </div>
          <div className="banner5-row">
            <div className="l">
              <img src={banner5} alt="" />
            </div>
            <div className="l">
              <h3>PRINT ONE, PLANT ONE</h3>
              <p>
                Each time you place an order with StressFreePrint, we plant a
                tree. Through our <br />
                partnership with Ecologi, every order helps to tackle climate
                change, deforestation
                <br />
                and extreme poverty.
              </p>
              <p>
                By employing local people to plant, maintain and protect forests
                in countries such as <br />
                Haiti, Brazil and Kenya, your print has the power to make a real
                impact.
              </p>
            </div>
          </div>
          <div className="banner6-row">
            <div className="l">
              <h3>WOODLAND CARBON SCHEME</h3>
              <p>
                StressFreePrint has signed up to an exciting initiative – the
                Woodland Carbon
                <br />
                Scheme – in partnership with the Woodland Trust and Premier
                Paper Group.
              </p>
              <p>
                By funding the Woodland Trust to plant native trees at
                accredited woodland creation <br />
                sites in the UK, we've offset thousands of tons of CO² emissions
                associated with the
                <br />
                manufacture and distribution of the paper we use.
              </p>
            </div>
            <div className="l">
              <img src={banner6} alt="" />
            </div>
          </div>
          <div className="banner7-row">
            <p>
              To date we’ve funded over 20,295 square metres of new native
              <br />
              woodland to remove around 812,000 kg of carbon dioxide.
            </p>
          </div>
          <div className="banner8-row">
            <div className="l">
              <h3>PLASTIC-FREE PACKAGING</h3>
              <p>
                Wherever possible, we use packaging which is recycled,
                recyclable and plastic-free.
                <br />
                Most of our products are now supplied in eco-friendly packaging.
              </p>
            </div>
            <div className="l">
              <img src={banner8} alt="" />
            </div>
          </div>
          <div className="banner9-row">
            <div className="l">
              <img src={banner9} alt="" />
            </div>
            <div className="l">
              <h3>ZERO CARBON DELIVERIES</h3>
              <p>
                Parcel transport is a major contributor to carbon emissions.
                That's why we fully
                <br />
                offset all the carbon generated during the delivery of your
                print.
              </p>
              <p>
                It’s good to know that, as a result of our carbon neutral
                initiative, all our deliveries <br />
                are 100% carbon neutral… at no extra cost to you.
              </p>
            </div>
          </div>
        </div>
        <FooterBar {...this.props} />
      </React.Fragment>
    );
  }
}

export default Environment;
