// 1
import col1 from "../image/col1.jpg";
import col1_1 from "../image/col1-1.jpg";
import col1_2 from "../image/col1-2.jpg";
import col1_3 from "../image/col1-3.jpg";

// 2
import col2 from "../image/col2.jpg";
import col2_1 from "../image/col2-1.jpg";

// 3
import col3 from "../image/col3.jpg";
import col3_1 from "../image/col3-1.jpg";

// 4
import col4 from "../image/col4.jpg";
import col4_1 from "../image/col4-1.jpg";
import col4_2 from "../image/col4-2.jpg";

// 5
import col5 from "../image/col5.jpg";
import col5_1 from "../image/col5-1.jpg";

// 6
import col6 from "../image/col6.jpg";
import col6_1 from "../image/col6-1.jpg";

// 7
import col7 from "../image/col7.jpg";
import col7_1 from "../image/col7-1.jpg";
import col7_2 from "../image/col7-2.jpg";
import col7_3 from "../image/col7-3.jpg";

// 8
import col8 from "../image/col8.jpg";
import col8_1 from "../image/col8-1.jpg";
import col8_2 from "../image/col8-2.jpg";

// 9
import col9 from "../image/col9.jpg";
import col9_1 from "../image/col9-1.jpg";
import col9_2 from "../image/col9-2.jpg";

// 10
import col10 from "../image/col10.jpg";
import col10_1 from "../image/col10-1.jpg";
import col10_2 from "../image/col10-2.jpg";

export const productList = [
  {
    id: 1,
    title: "Hardcover Notebooks",
    description: [
      "Custom printed hardcover notebooks",
      "Perfect for premium journals, planners and dairies",
      "Printed in full colour throughout with your artwork",
      "Luxurious, lay-flat, thread-sewn binding",
      "Sturdy 2500 micron matt laminated cover",
      "Optional ribbon in a choice of colours",
      "Alternative sizes, papers and finishings available on request",
    ],
    size: "A5 (148x210mm)",
    quantity: "5",
    image: [col1, col1_1, col1_2, col1_3],
    price: "24.41",
  },

  {
    id: 2,
    title: "Wire Bound Notebooks",
    description: [
      "Wire bound notebooks with custom printed cover",
      "100 pages (50 leaves), plus cover",
      "Choice of plain or ruled inner pages",
      "Alternative formats, papers and finishings available on request",
    ],
    size: "A5 (148x210mm)",
    quantity: "5",
    image: [col2, col2_1],
    price: "66.41",
  },
  {
    id: 3,
    title: "Essential A4 Silk Posters",
    description: [
      "Ideal size for displaying in a window or on a noticeboard",
      "Printed in vibrant full colour onto high quality silk paper",
      "Choice of different paper weights",
      "Single or double sided printing",
      "Eco packaging and zero carbon delivery",
    ],
    size: "A4 (210x297mm)",
    quantity: "25",
    image: [col3, col3_1],
    price: "26.74",
  },
  {
    id: 4,
    title: "WaterProof PVC Poster",
    description: [
      "Built to last with tear-proof, weather-resistant 440gsm PVC",
      "Perfect for outdoor advertising, events and A-boards",
      `Vibrant prints that won't fade, even in harsh conditions`,
      "Easy to clean with mild soap and water, no harsh chemicals",
      "Ideal for gyms, sports centres and restaurants",
    ],
    size: "A4 (210x297mm)",
    quantity: "1",
    image: [col4, col4_1, col4_2],
    price: "13.82",
  },
  {
    id: 5,
    title: "Essential Bookmarks",
    description: [
      "An economical giveaway that will be used and reused",
      "Add a tassel for extra decoration and impact",
      "Lamination is available for extra protection from handling",
      "Eco packaging and zero carbon delivery",
    ],
    size: "55 x 200",
    quantity: "50",
    image: [col5, col5_1],
    price: "25.41",
  },
  {
    id: 6,
    title: "Brown Kraft Bookmarks",
    description: [
      "An economical giveaway that will be used and reused",
      "Printed on rustic, 100% recycled brown Kraft",
      "Add a tassel for extra decoration and impact",
      "Eco packaging and zero carbon delivery",
    ],
    size: "55 x 200",
    quantity: "50",
    image: [col6, col6_1],
    price: "29.21",
  },
  {
    id: 7,
    title: "Essential Wedding Invitations (no fiold)",
    description: [
      "Carefully crafted, exquisitely finished, and unique to you",
      "Available in a wide choice of paper weights and finishes",
      "Don't forget to order envelopes",
      "Eco packaging and zero carbon delivery",
    ],
    size: "A5 (148x210mm)",
    quantity: "25",
    image: [col7, col7_1, col7_2, col7_3],
    price: "27.08",
  },
  {
    id: 8,
    title: "Plantable Seed Paper Wedding Invitations",
    description: [
      "Plantable invitations printed onto gorgeous seed paper",
      "Plant them, water them, watch them grow",
      "Create a lasting, floral reminder of your big day",
      `Optional personalisation with guests' names`,
      "Available in standard and custom shapes",
      "Eco packaging and zero carbon delivery",
    ],
    size: "A5 (148x210mm)",
    quantity: "5",
    image: [col8, col8_1, col8_2],
    price: "32.14",
  },
  {
    id: 9,
    title: "Folded Brown Kraft Wedding Invitations",
    description: [
      "Create a buzz around your event with gorgeous Kraft invites",
      "100% recycled, acid free stock with a rustic aesthetic",
      "Supplied creased and folded, ready to send",
      "Eco packaging and zero carbon delivery",
      `Don't forget to order envelopes`,
    ],
    size: "A5 (148x210mm)",
    quantity: "25",
    image: [col9, col9_1, col9_2],
    price: "38.43",
  },
  {
    id: 10,
    title: "Brown Kraft Wedding Invitations (No Fold)",
    description: [
      "Perfect for rustic and vintage themed weddings",
      "100% recycled with gorgeous visible flecks",
      "Eco packaging and zero carbon delivery",
      `Don't forget to order envelopes`,
    ],
    size: "A5 (148x210mm)",
    quanyity: "25",
    image: [col10, col10_1, col10_2],
    price: "32.76",
  },
];

export const getProductDetailById = (id) => {
  for (let i = 0; i < productList.length; i++) {
    if (productList[i].id === id) {
      return productList[i];
    }
  }
};
