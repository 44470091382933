import React, { Component } from "react";
import HeaderBar from "./common/HeaderBar";
import FooterBar from "./common/FooterBar";
import guarantee from "../image/usp-guarantee.svg";
import "../style/trems.scss";

class Terms extends Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <React.Fragment>
        <HeaderBar {...this.props} />
        <div className="trems">
          <h2>TERMS & CONDITIONS</h2>
          <p>
            By ordering Work from the Company you are deemed to have understood
            and accepted these Terms and Conditions.
          </p>
          <p>
            <b>1. Price Variation And Invoicing </b>
          </p>
          <p>
            a) The Company may adjust prices for its Work as displayed on the
            Website at any time without prior notice. While every effort is made
            to ensure the accuracy of pricing information, occasional errors may
            occur. In the event of such an error, the Company will promptly
            notify the Customer, who will have the option to either accept the
            revised price or cancel the order.
          </p>
          <p>
            b) Bespoke quotes provided by the Company remain valid for the
            duration specified within the quote. The Company commits to
            honouring this quoted price within this timeframe, provided the
            order is placed by the Customer during this period. However, should
            there be unforeseen increases in costs (e.g., materials, labour, or
            other production costs) or discrepancies identified after the
            initial quotation, the Company reserves the right to adjust the
            quote accordingly. Any such adjustments will be promptly
            communicated to the Customer, and the Customer will have the option
            to continue with the order at the revised price or decline. All
            bespoke quotes are given on an Errors and Omissions Excepted (E&OE)
            basis.
          </p>
          <p>
            c) Prices agreed upon order are conditional upon no subsequent
            changes to the order initiated by the Customer. Any post-order
            alterations by the Customer may result in additional charges, which
            will be communicated and billed separately.
          </p>
          <p>
            d) All invoices from the Company will be issued electronically. By
            placing an order, the Customer consents to receiving invoices in
            this manner.
          </p>
          <p>
            <b>2. Proofs </b>
          </p>
          <p>
            a) Due to their inherent nature, proofs supplied by the Company
            shall be accurate for layout and content only and it is understood
            and accepted that proofs shall not be accurate for colour comparison
            to the finished Work.{" "}
          </p>
          <p>
            b) All reasonable efforts will be made by the Company to obtain the
            best possible colour reproduction on the Customer′s Work but
            variation is inherent in the printing process and it is understood
            and accepted as reasonable that the Company shall not be required to
            guarantee an exact match in colour or texture between the Customer′s
            photograph, transparency, proof, Electronic File, Print Ready File,
            previously printed matter (whether printed by the Company or by a
            third party) or any other materials supplied by the Customer and the
            finished Work. It is accepted and understood that Pantone colours
            (also known as spot colours) shall not be accurately reproduced due
            to the inherent nature of our full colour printing process.
          </p>
          <p>
            <b>3. Payment And Delivery </b>
          </p>
          <p>
            a) Delivery prices quoted or published on the Website are for
            delivery to a single address in the Mainland of the United Kingdom
            excluding Highlands, Islands, or Overseas, unless otherwise stated.
            Split deliveries, alternative delivery methods or schedules, and
            deliveries to other destinations may be available on request and
            will be subject to additional charges.
          </p>
          <p>
            b) Delivery of the Work shall be accepted when tendered. If the
            Customer or other authorised signatory is not present at the agreed
            delivery address to accept delivery, the Company shall be entitled
            to charge extra for any subsequent redelivery attempts.{" "}
          </p>
          <p>
            c) Completion and delivery turnaround or lead-times (measured in
            working days (not including weekends, public holidays) from receipt
            of cleared or authorised payment and of approval by the Customer in
            writing of any proofs) are a guide only and, whilst the Company will
            make every effort to adhere to proposed timescales, time is not of
            the essence in any contract with the Customer. In the event that a
            Customer requests and pays for an upgraded turnaround or delivery
            service and the Company fails to deliver within the agreed
            lead-time, the Company's liability is limited to refunding to the
            Customer only the surcharge sum paid in respect of the turnaround or
            delivery service upgrade.
          </p>
          <p>
            d) Unless otherwise agreed in writing, (in which case an extra
            charge may be made), delivery will be to kerbside at the Customer's
            address and the Customer will make arrangements for off-loading and
            for any additional transportation to its storage facility.{" "}
          </p>
          <p>
            e) Where the Work is to be delivered in instalments each delivery
            shall constitute a separate contract and failure by the Company to
            deliver any one or more of the instalments in accordance with these
            Terms and Conditions or any claim by the Customer in respect of any
            one or more of the instalments shall not entitle the Customer to
            treat the order as a whole as repudiated or cancelled.{" "}
          </p>
          <p>
            f) All goods remain the property of the Company until payment is
            received in full.
          </p>
          <p>
            <b>4. Credit Accounts</b>
          </p>
          <p>
            a) At our sole discretion, we may, but are not obliged to, offer the
            Customer a credit account, subject to a designated credit limit.
          </p>
          <p>
            b) Customers holding approved credit account facilities with the
            Company will be required to settle their accounts on or before the
            30th of the month following the month of invoice, while ensuring
            that the total outstanding balance does not exceed the designated
            credit limit. Failure to comply with these Terms and Conditions may
            result in the withdrawal of credit facilities, and any sums
            outstanding will become payable, in full, immediately upon notice.
            The Customer may also lose the benefit of any previously agreed
            discounts or special terms.
          </p>
          <p>
            c) The Company reserves the right to withdraw credit facilities and
            demand immediate payment of all monies outstanding at their
            discretion, particularly if the credit limit is exceeded.
          </p>
          <p>
            d) Where credit terms are exceeded, we reserve the right to charge
            Statutory Interest on the outstanding balance, including any amount
            beyond the designated credit limit.
          </p>
          <p>
            e) If legal proceedings are issued, the Customer will be liable to
            pay all the Company's associated costs, including, but not limited
            to, legal fees, court fees, solicitor fees, and debt collection
            fees, arising from any breach of credit limit terms or
            non-compliance with payment obligations.
          </p>
          <p>
            <b>5. Materials Supplied Or Specified By The Customer </b>
          </p>
          <p>
            a) It is the Customer's responsibility to ensure that any materials
            supplied or specified for the Work, whether communicated online or
            offline, are suitable and appropriate for the intended use. The
            Company shall not be held liable for any issues arising from the use
            of unsuitable materials.
          </p>
          <p>
            b) It is the Customer's responsibility to maintain a copy of any
            original Electronic File or Print Ready File provided by the
            Customer.{" "}
          </p>
          <p>
            c) The Company shall not be responsible for checking the accuracy or
            suitability of supplied input from an Electronic File or Print Ready
            File.{" "}
          </p>
          <p>
            d) Without prejudice to clause 5c, if an Electronic File or Print
            Ready File is not suitable for outputting on equipment normally
            adequate for such purposes without adjustment or other corrective
            action the Company may make a charge for any resulting additional
            cost incurred or may reject the file without prejudice to his rights
            to payment for Preliminary Work or Work done/materials purchased.
          </p>
          <p>
            e) Customer's property and all property supplied to the Company by
            or on behalf of the Customer shall while it is in the possession of
            the Company or in transit to or from the Customer are deemed to be
            at the Customer's risk unless otherwise agreed in writing and the
            Customer should insure accordingly.{" "}
          </p>
          <p>
            f) The risk in the Work and all goods delivered in connection with
            the Work shall pass to the Customer on delivery and the Customer
            should insure accordingly.{" "}
          </p>
          <p>
            g) On completion of the Work, the Company will store the Customer′s
            materials, Electronic Files, Print Ready Files and Work for a
            maximum of 30 days, after which time they will be destroyed without
            further notice.
          </p>
          <p>
            <b>6. Materials And Equipment Supplied By The Company </b>
          </p>
          <p>
            a) Metal, film and other materials owned by the Company and used in
            the production of Intermediates, type, plates, film-setting,
            negatives, positives, Electronic Files, Print Ready Files and other
            production processes, together with items thereby produced, shall
            remain the Company's exclusive property.{" "}
          </p>
          <p>
            b) Film, plates, tapes, discs, Electronic Files, Print Ready Files
            or other Work will be destroyed immediately after the order is
            executed unless written arrangements are made to the contrary.
          </p>
          <p>
            c) The Company shall not be obliged to download any digital data
            from his equipment or supply the same to the Customer on disc, via
            email or by any communication link.
          </p>
          <p>
            <b>7. Guarantees And Claims</b>
          </p>
          <p>
            a) The Company's "100% Satisfaction" Guarantee applies exclusively
            to Work provided by the Company. It does not replace or negate other
            warranties, including fitness for a particular purpose and
            non-infringement. Issues resulting from customer-supplied artwork,
            content, and subjective design elements, including colour
            perception, are excluded. Orders without clear guidelines or
            comprehensive specifications are not covered by the Guarantee. The
            Company is not accountable for damages occurring post-delivery, nor
            for defects resulting from misuse, neglect, or non-standard use.
          </p>
          <p>
            b) Notification of damage, delay, or loss of Work during transit or
            of non-receipt must be submitted in writing to both the Company and
            the carrier within three working days following delivery or, in
            instances of non-delivery, within three working days after receiving
            notification of dispatch. Formal claims related to these issues
            should be presented in writing to the Company and the carrier within
            seven working days of delivery or, for non-delivery, within seven
            working days post notification of dispatch. Any other claims should
            be directed in writing to the Company within 28 days after delivery.
            The Company's liability is contingent upon adherence to these terms,
            barring circumstances where the Customer proves it was unfeasible to
            meet these conditions and that the claim was lodged as promptly as
            practicable.
          </p>
          <p>
            c) Upon receiving their Work, Customers are encouraged to promptly
            inspect a representative sample of the Work to ensure it meets
            expectations. This sample should ideally be a random selection from
            different parts of the batch, ensuring it is indicative of the
            overall quality and correctness of the entire order. If the Customer
            has already used, distributed, or otherwise consumed a portion of
            the Work before identifying any defects or discrepancies in the
            items, the Guarantee provided by the Company will apply exclusively
            to the unused and undistributed portion of the Work. The Company
            acknowledges the significant set-up costs inherent in the printing
            process; therefore, in case of a valid claim, the determination of
            compensation or remediation for the unused items will take into
            account both the quantity of the unused items and the original
            set-up costs. Notwithstanding the above, the Company's total
            liability under this Guarantee shall not exceed the original value
            of the Work.
          </p>
          <p>
            d) The Guarantee does not extend to third parties, including but not
            limited to representatives, agents, or any other parties that did
            not directly purchase from the Company.
          </p>
          <p>
            e) If a reprint is requested by the Customer, the Company commits to
            addressing discrepancies in line with the original requirements,
            adhering to the design and specifications of the original order.
            Should a refund be preferred, it will typically be processed through
            the original payment method within 14 business days. The Company may
            decide, at its sole discretion, to offer a refund if it determines
            that a reprint may not satisfy the Customer's requirements.
          </p>
          <p>
            f) Should a Customer raise a concern or claim regarding the quality
            or correctness of the Work, the Company, at its discretion, may
            request printed samples or the return of all unused or undistributed
            items, at the Company's expense, for verification purposes. This is
            to assess the validity of the claim and determine appropriate
            corrective actions.
          </p>
          <p>
            <b>8. Liability </b>
          </p>
          <p>
            a) Insofar as is permitted by law where Work is defective for any
            reason, including negligence, the Company's liability (if any) shall
            be limited to rectifying such defect, or crediting its value against
            any invoice raised in respect of the Work.{" "}
          </p>
          <p>
            b) Where the Company performs its obligations to rectify defective
            Work under this condition the Company shall not be liable for
            indirect loss, consequential loss or third party claims occasioned
            by defective Work and the Customer shall not be entitled to any
            further claim in respect of the Work nor shall the Customer be
            entitled to repudiate the contract, refuse to pay for the Work or
            cancel further deliveries.{" "}
          </p>
          <p>
            c) The Company shall not be liable for indirect loss, consequential
            loss or third party claims occasioned by delay in completing the
            Work or for any loss to the Customer arising from delay in transit,
            whether as a result of the Company's negligence or otherwise.{" "}
          </p>
          <p>
            d) Where the Company offers to replace defective Work the Customer
            must accept such an offer unless he can show clear cause for
            refusing so to do. If the Customer opts to have the Work re-done by
            any third party without reference to the Company the Customer
            automatically revokes his right to any remedy from the Company,
            including but not exclusively the right to a credit in respect of
            Work done by the Company.{" "}
          </p>
          <p>
            e) Where the Work will be forwarded by or on behalf of the Customer
            to a third party for further processing the Customer will be deemed
            to have inspected and approved the Work prior to forwarding and the
            Company shall accept no liability for claims arising subsequent to
            the third party′s processing.
          </p>
          <p>
            f) The Company reserves the right to reject any Work forwarded to
            them after initial processing by a third party as soon as is
            reasonably practicable without processing the Work any further.
            Should the Customer require the Company notwithstanding to continue,
            then the Company is only obliged to do so after confirmation from
            the Customer in writing.
          </p>
          <p>
            g) Nothing in these Terms and Conditions shall exclude the Company's
            liability for death or personal injury as a result of its
            negligence.
          </p>
          <p>
            <b>9. General Lien </b>
          </p>
          <p>
            a) Without prejudice to other remedies, in respect of all unpaid
            debts due from the Customer the Company shall have a general lien on
            all goods and property of or provided by the Customer in his
            possession (whether worked on or not) and shall be entitled on the
            expiration of 14 days' notice to dispose of such goods or property
            as agent for the Customer in such manner and at such price as he
            thinks fit and to apply the proceeds towards such debts, and shall
            when accounting to the Customer for any balance remaining be
            discharged from all liability in respect of such goods or property.
          </p>
          <p>
            <b>10. Illegal Matter </b>
          </p>
          <p>
            a) The Company reserves the right to refuse the printing of any
            content that it unilaterally determines to be potentially illegal,
            offensive, excessively controversial, defamatory, or in violation of
            the intellectual property or other rights of any third party.
          </p>
          <p>
            b) The Company shall be indemnified by the Customer in respect of
            any claims, costs and expenses arising out of the printing by the
            Company for the Customer of any illegal or unlawful matter including
            matter which is libellous or infringes copyright, patent, design or
            any other proprietary or personal rights. The indemnity shall
            include (without limitation) any amounts paid on legal advice in
            settlement of any claim that any matter is libellous or such an
            infringement.
          </p>
          <p>
            <b>11. Copyright </b>
          </p>
          <p>
            a) The Customer shall be responsible for obtaining all necessary
            authorities and consents to reproduce materials (e.g., artwork,
            photographs, copyright text) prior to instructing the Company. The
            Customer shall indemnify and hold the Company harmless against any
            claims, costs, expenses, losses, and damages arising from or
            incurred due to the reproduction of materials by the Company, which
            infringes the intellectual property or other rights of any third
            party. The Company may request proof of such permissions at its
            discretion.
          </p>
          <p>
            b) All design Work commissioned or ordered by the Customer and
            originated by the Company shall, as to its material elements and as
            to the copyright or design right in relation to it, belong to the
            Company.{" "}
          </p>
          <p>
            c) The Customer shall not have any right to reproduce or authorise
            any other person to reproduce any such design Work in whole or in
            part or do any act which would, in the absence of written
            authorisation by the Company, infringe any copyright or design right
            which may subsist in relation to any such design Work.{" "}
          </p>
          <p>
            d) The Company may, at the request of the Customer, assign the
            copyright and design right of design Work to the Customer upon
            payment of the Company′s expenses in relation to such assignment.{" "}
          </p>
          <p>
            <b>12. Suitability Of Work Supplied</b>
          </p>
          <p>
            a) It is the Customer's obligation to ensure that Work ordered from
            the Company aligns with their intended purposes. The Company shall
            not bear responsibility if Work is deemed unsuitable for its
            intended application, which includes but is not limited to: the
            incorrect paper type for post-processing through specific printing
            or finishing devices, adhesive labels with specific requirements
            such as removability, and products in contact with food or its
            packaging.{" "}
          </p>
          <p>
            b) Any consequences or issues arising from the Customer's selection
            without prior consultation with the Company will be the sole
            responsibility of the Customer.
          </p>
          <p>
            <b>13. Force Majeure </b>
          </p>
          <p>
            a) The Company is not liable for any failure to fulfill its
            contractual obligations if such failure is due to events beyond its
            reasonable control. This includes, but is not limited to, natural
            disasters (such as floods, earthquakes, and hurricanes), acts of
            war, pandemics, legal enactments, fires, and disruptions in supply
            chains.
          </p>
          <p>
            b) In the event of such unforeseen circumstances, the Customer may
            choose to terminate the contract by providing written notice to the
            Company. In this scenario, the Customer would be responsible only
            for costs associated with Work already completed and materials
            already procured. Beyond this, the Customer is expected to accept
            delivery when it becomes feasible, considering the prevailing
            circumstances.
          </p>
          <p>
            c) Both parties acknowledge that unforeseen events can lead to
            significant delays or disruptions, and a spirit of cooperation and
            understanding is expected to navigate these challenges.
          </p>
          <p>
            <b>14. Law </b>
          </p>
          <p>
            a) These Terms and Conditions shall be governed by and construed in
            accordance with the laws of England and Wales, and the parties
            submit to the exclusive jurisdiction of the courts of England and
            Wales.
          </p>
          <p>
            <b>15. Notices </b>
          </p>
          <p>
            a) All specifications and notices relied on by either Party and all
            variations to this agreement must be in writing and bear the
            signature of an authorised representative for validation.
          </p>
          <p>
            <b>16. Variation To Terms And Conditions </b>
          </p>
          <p>
            a) The Company may amend these Terms and Conditions from time to
            time. The most recent version of these Terms and Conditions can be
            accessed via the Website or can be requested in writing.
          </p>
          <p>
            b) It is the Customer's responsibility to review and familiarise
            themselves with the latest Terms and Conditions each time they place
            an order with the Company. By proceeding with an order, the Customer
            acknowledges and agrees to the then-current Terms and Conditions.
          </p>
          <p>
            <b>17. Consumers </b>
          </p>
          <p>
            a) Nothing in these Terms and Conditions shall affect the rights of
            Consumers.{" "}
          </p>
          <p>
            b) Due to the bespoke made-to-order nature of printed matter the
            Customer shall not be entitled to a "cooling off" period to cancel
            the Work ordered. However, if the Customer cancels any contract the
            Company shall only charge the Customer for any materials purchased
            for the contract and Preliminary Work or Work already undertaken up
            to the time of cancellation.
          </p>
          <p>
            <b>18. Severability </b>
          </p>
          <p>
            a) All clauses and sub-clauses of this Agreement are severable and
            if any clause or identifiable part thereof is held to be
            unenforceable by any court of competent jurisdiction then such
            enforceability shall not affect the enforceability of the remaining
            provisions or identifiable parts thereof in these Terms and
            Conditions.{" "}
          </p>
          <p>
            <b>19. Definitions </b>
          </p>
          <p>
            a) "Company" means Asterisk Creative Consultants Ltd trading as
            StressFreePrint registered in England number 2912817 who provides
            the goods or services under these Terms and Conditions.{" "}
          </p>
          <p>
            b) "Customer" means the party contracting with the Company to
            acquire the Work supplied under these Terms and Conditions.{" "}
          </p>
          <p>
            c) "Website" means any Website(s) owned and operated by the Company.
          </p>
          <p>
            d) "Work" refers to the specific products or services provided by
            the Company as described in each individual order or contract. This
            can include, but is not limited to, intermediate or finished
            physical products, digital products, and consulting or advisory
            services.
          </p>
          <p>
            e) "Intermediates" means all products produced during the
            manufacturing process including non-exhaustively discs, film,
            plates, and intellectual property.{" "}
          </p>
          <p>
            f) "Preliminary Work" means all work done in the concept and
            preparatory stages (including non-exhaustively design, artwork,
            proofs, colour matching).{" "}
          </p>
          <p>
            g) "Electronic File" means any text, illustration or other matter
            supplied or produced by either Party in digitised form on disc, by
            email, modem, internet, FTP, ISDN or any other communication link.{" "}
          </p>
          <p>
            h) "Print Ready File" means artwork supplied by the Customer ready
            for plate making and production, without further keyboard
            intervention by the Company, in digitised form on disc, by email,
            modem, internet, FTP, ISDN or any other communication link.{" "}
          </p>
          <p>
            i) "Guarantee" refers to the Company's commitment to the Customer
            that the products and services supplied will conform to the stated
            specifications and quality standards.
          </p>
          <p>
            The above Terms and Conditions constitute the entire Agreement
            between the Customer and the Company and supersede any previous
            agreements, warranties, representations, undertakings or
            understandings between the parties and may not be varied except in
            writing.{" "}
          </p>
          <p>Updated: 4 November 2023</p>
          <div className="banner1">
            <div className="img">
              <img src={guarantee} alt="" />
            </div>
            <p>
              Everything we do is covered by our{" "}
              <span>100% StressFree Guarantee.</span>
            </p>
            <p>
              If you're not completely satisfied with your purchase, we'll
              reprint or refund your order. No fuss, no faff.
            </p>
          </div>
        </div>
        <FooterBar {...this.props} />
      </React.Fragment>
    );
  }
}

export default Terms;
