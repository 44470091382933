import React, { Component } from "react";
import {
  BrowserRouter as Router,
  Switch,
  HashRouter,
  Route,
  BrowserRouter,
} from "react-router-dom";

import Popup from "./components/common/Popup";
import Loading from "./components/common/Loading";
import queryString from "query-string";
import Home from "./components/Home";
import Checkout from "./components/Checkout";
import Environment from "./components/Environment";
import Delivery from "./components/Delivery";
import Guarantee from "./components/Guarantee";
import Terms from "./components/Terms";
import Privacy from "./components/Privacy";
import AboutUs from "./components/AboutUs";
import ProductDetail from "./components/ProductDetail";
import Cart from "./components/Cart";

class App extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <HashRouter>
        <React.Fragment>
          <Popup {...this.props} />
          <Switch>
            <Route
              path="/ProductDetail/:id"
              render={(props) => (
                <ProductDetail key={props.match.params.id} {...props} />
              )}
            />
            <Route path="/Cart" component={Cart}></Route>
            <Route path="/About" component={AboutUs}></Route>
            <Route path="/Privacy" component={Privacy}></Route>
            <Route path="/Terms" component={Terms}></Route>
            <Route path="/Guarantee" component={Guarantee}></Route>
            <Route path="/Delivery" component={Delivery}></Route>
            <Route path="/Environment" component={Environment}></Route>
            <Route path="/Checkout" component={Checkout}></Route>
            <Route path="/" component={Home}></Route>
          </Switch>
        </React.Fragment>
      </HashRouter>
    );
  }
}
export default App;
