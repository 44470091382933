import React, { Component } from "react";
import HeaderBar from "./common/HeaderBar";
import FooterBar from "./common/FooterBar";
import "../style/cart.scss";
import Remove from "../icon/RemoveIcon";

class Cart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cartArr: [],
      cartNum: 0,
      cartTotal: 0,
    };
    this.remove = this.remove.bind(this);
    this.routeCheckout = this.routeCheckout.bind(this);
  }

  headerRef = React.createRef();

  componentDidMount() {
    let cartTotal = 0;
    if (localStorage.getItem("cartArr")) {
      let cartArr = JSON.parse(localStorage.getItem("cartArr"));
      let cartNum = JSON.parse(localStorage.getItem("cartNum"));
      cartArr.map((item) => {
        item.total = item.price;
        cartTotal += parseFloat(item.total);
      });
      this.setState({ cartArr, cartNum, cartTotal: cartTotal.toFixed(2) });
    }
  }

  remove(index) {
    let { cartArr, cartTotal } = this.state;
    cartTotal = cartTotal - parseFloat(cartArr[index].price);
    cartArr.splice(index, 1);
    this.setState({ cartArr, cartTotal: cartTotal.toFixed(2) });
    localStorage.setItem("cartArr", JSON.stringify(cartArr));
    if (this.headerRef) {
      this.headerRef.current.setState({ cartArr });
    }
  }

  routeCheckout() {
    let { history } = this.props;
    history.push("/Checkout");
  }

  render() {
    let { cartArr, cartTotal } = this.state;
    return (
      <React.Fragment>
        <HeaderBar {...this.props} ref={this.headerRef} />
        <div className="cart-page">
          {cartArr.length > 0 ? (
            <React.Fragment>
              <h2>YOUR CART</h2>
              <div className="cartArr">
                {cartArr.map((item, index) => {
                  return (
                    <div className="row" key={index}>
                      <div className="title">
                        {item.title}{" "}
                        <Remove
                          size={25}
                          className="icon"
                          onClick={() => this.remove(index)}
                        />
                      </div>
                      <div className="img-row">
                        <div className="left">
                          <div className="img">
                            <img src={item.image[0]} alt="" />
                          </div>
                          <div className="info">
                            <div className="li">
                              <div className="title">Finished Size : </div>
                              {item.size}
                            </div>
                            <div className="li">
                              <div className="title">Delivery : </div>
                              Within 3 - 5 working days
                            </div>
                          </div>
                        </div>
                        <div className="right">
                          <div className="quantity">
                            <div className="quan">
                              <b>QTY</b> {item.quantity}
                            </div>
                            <div className="price">￡{item.price}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className="total-row">
                <div className="total-box">
                  <div className="subtotal">
                    <div className="title">Subtotal</div>{" "}
                    <div className="price">￡{cartTotal}</div>
                  </div>
                  <div className="total">
                    <div className="title">Grand Total</div>
                    <div className="price">￡{cartTotal}</div>
                  </div>
                </div>
              </div>
              <div className="check-row">
                <div className="return">{"<"} Continue Shopping</div>
                <div className="btn" onClick={this.routeCheckout}>
                  Proceed to Checkout
                </div>
              </div>
            </React.Fragment>
          ) : (
            <div className="no-cart">
              <h3>SHOPPING CART IS EMPTY</h3>
              <p>You have no items in your shopping cart.</p>
              <p>Click here to continue shopping.</p>
            </div>
          )}
        </div>
        <FooterBar {...this.props} />
      </React.Fragment>
    );
  }
}

export default Cart;
