import React, { Component } from "react";
import HeaderBar from "./common/HeaderBar";
import FooterBar from "./common/FooterBar";
import "../style/aboutUs.scss";
import banner1 from "../image/about_us_hero_team.jpg";
import banner3 from "../image/about-page-press.jpg";
import banner4 from "../image/landing_page_hammock.jpg";
import banner5 from "../image/woodland2-xpro.jpg";
import banner6 from "../image/about_us_smiley_boxes.jpg";

class AboutUs extends Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    window.scrollTo(0, 0);
  }


  render() {
    return (
      <React.Fragment>
        <HeaderBar {...this.props} />
        <div className="about">
          <div className="banner1-row">
            <img src={banner1} alt="" />
            <div className="banner-mask">OUR STORY</div>
          </div>
          <div className="banner2-row">
            <p>Discover a better way to print...</p>
          </div>
          <div className="banner3-row">
            <div className="l">
              <h3>STRESS FREE</h3>
              <p>
                Our story began back in 2006 when we helped pioneer the concept
                of online print. <br /> From humble beginnings, we’ve grown to
                become one of the UK's most recognised <br /> print brands. But
                we’ve never lost that original pioneering spirit.
              </p>
              <p>
                Fiercely independent, we’re free to follow our passion for
                exceptional quality <br /> and unrivalled service. In the
                process, we hope to make the world just a little more <br />{" "}
                StressFree.
              </p>
            </div>
            <div className="l">
              <img src={banner3} alt="" />
            </div>
          </div>
          <div className="banner3-row">
            <div className="l">
              <img src={banner4} alt="" />
            </div>
            <div className="l">
              <h3>FAFF FREE</h3>
              <p>
                From day one, our aim has been to remove all the complexity and
                hassle out of print <br /> buying. "Print without the palaver".
              </p>
              <p>
                So, whether you're in need of a few simple flyers, advice
                regarding a bespoke project <br />
                or a turnkey solution to manage your ongoing print requirements,
                our friendly team
                <br />
                are standing by to take the strain.
              </p>
            </div>
          </div>
          <div className="banner2-row">
            <p>
              "Taking the time to care certainly isn't the easiest approach, but
              it means we <br /> can be confident that we’re always producing
              work of the highest standard."
            </p>
          </div>
          <div className="banner3-row">
            <div className="l">
              <img src={banner5} alt="" />
            </div>
            <div className="l">
              <h3>GUILT FREE</h3>
              <p>
                We're very proud of our green credentials, although we're always
                working to reduce <br /> our impact on the environment even
                further.
              </p>
              <p>
                It's good to know that the paper we use is sustainably sourced,
                our deliveries are <br />
                carbon neutral and that we fund the planting of a tree every
                time you order from us.
              </p>
            </div>
          </div>
          <div className="banner3-row">
            <div className="l">
              <h3>RISK FREE</h3>
              <p>
                Rather than spending a fortune on expensive advertising, our
                success has been built <br />
                on word-of-mouth recommendations from customers both old and
                new. We love <br />
                what we do, but we're only ever happy when you're happy. That's
                why everything we <br />
                do is backed by our 100% satisfaction guarantee and why you can
                be confident that <br />
                your next print project will be stress free, faff free, guilt
                free... and risk free.
              </p>
            </div>
            <div className="l">
              <img src={banner6} alt="" />
            </div>
          </div>
        </div>
        <FooterBar {...this.props} />
      </React.Fragment>
    );
  }
}

export default AboutUs;
