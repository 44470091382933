import React, { Component } from "react";
import HeaderBar from "./common/HeaderBar";
import FooterBar from "./common/FooterBar";
import BannerBar from "./common/BannerBar";
import "../style/productDetail.scss";
import quantity from "../image/quantity.png";
import Star from "../icon/Star";
import HalfStar from "../icon/HalfStar";
import banner1 from "../image/banner2.jpg";
import { productList, getProductDetailById } from "../apis/GetProduct";

class ProductDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      product: null,
      img: "",
      cartArr: [],
    };
    this.changeImg = this.changeImg.bind(this);
  }

  headerRef = React.createRef();

  componentDidUpdate(prevProps) {
    // 检查URL参数是否变化
    if (this.props.match.params.id !== prevProps.match.params.id) {
      // 执行当URL参数变化时需要进行的操作
      this.fetchData(this.props.match.params.id);
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    let { location } = this.props;
    let id = parseInt(location.pathname.replace("/ProductDetail/", ""));
    let product = getProductDetailById(id);
    this.setState({ product, img: product.image[0] });
    if (localStorage.getItem("cartArr")) {
      let cartArr = JSON.parse(localStorage.getItem("cartArr"));
      this.setState({ cartArr });
    }
  }

  changeImg(index) {
    const { product } = this.state;
    this.setState({ img: product.image[index] });
  }

  AddToCart(product) {
    let { cartArr } = this.state;
    cartArr.push(product);
    localStorage.setItem("cartArr", JSON.stringify(cartArr));
    if (this.headerRef) {
      this.headerRef.current.setState({ cartArr });
    }
    let { history } = this.props;
    history.push("/Cart");
  }

  render() {
    const { product, img } = this.state;
    return (
      <React.Fragment>
        <HeaderBar {...this.props} ref={this.headerRef} />
        <BannerBar {...this.props} />
        <div className="productDetail">
          <div className="left">
            <div className="left-img">
              {product &&
                product.image.map((item, index) => {
                  return (
                    <div
                      className="row"
                      key={index}
                      onClick={() => this.changeImg(index)}
                    >
                      <img src={item} alt="" />
                    </div>
                  );
                })}
            </div>
            <div className="big-img">
              <img src={img} alt="" />
            </div>
          </div>
          <div className="right">
            <div className="title">
              {product && product.title.toUpperCase()}{" "}
              <img src={quantity} alt="" />
            </div>
            <div className="descrption">
              {product &&
                product.description.map((item, index) => {
                  return (
                    <div className="li" key={index}>
                      - {item}
                    </div>
                  );
                })}
            </div>
            <div className="tar">
              <div className="til">Finished Size</div>
              {product && product.size}
            </div>
            <div className="tar">
              <div className="til">Delivery</div>
              With 3-5 working days
            </div>
            <div className="tar">
              <div className="til">Quantity</div>
              {product && product.quantity}
            </div>
            <div className="price">￡{product && product.price}</div>
            <div className="btn" onClick={() => this.AddToCart(product)}>
              Add to Basket {">"}
            </div>
            <div className="star-title">
              We're rated <b>EXCELLENT</b>
            </div>
            <div className="star">
              <Star size={35} />
              <Star size={35} />
              <Star size={35} />
              <Star size={35} />
              <HalfStar size={35} />
            </div>
          </div>
        </div>
        <div className="banner3">
          <img src={banner1} alt="" />
          <div className="contain">
            <div className="box">
              <div className="box-content">
                <div className="contain-box">
                  <div className="title">PRINT ONE {">"} PLANT ONE</div>
                  <div className="p">
                    <i>
                      Find out how your print helps tackle <br /> deforestation,
                      poverty and climate change
                    </i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <FooterBar {...this.props} />
      </React.Fragment>
    );
  }
}

export default ProductDetail;
