import React, { Component } from "react";
import "../../style/common/headerBar.scss";
import Star from "../../icon/Star";
import HalfStar from "../../icon/HalfStar";
import logo from "../../image/logo.png";
import ShoppingCart from "../../icon/ShoppingCart";
import close from "../../image/close.svg";

class HeaderBar extends Component {
  constructor(props) {
    super(props);
    this.state = { cartShow: false, cartArr: [] };
    this.routePage = this.routePage.bind(this);
  }

  routePage(type) {
    let { history } = this.props;
    switch (type) {
      case "home":
        history.push("/");
        break;
      case "about":
        history.push("/About");
        break;
      case "privacy":
        history.push("/Privacy");
        break;
      case "cart":
        history.push("/Cart");
        break;
      default:
        break;
    }
  }
  
  componentDidMount() {
    if (localStorage.getItem("cartArr")) {
      let cartArr = JSON.parse(localStorage.getItem("cartArr"));
      this.setState({ cartArr });
    }
  }

  render() {
    let { cartArr } = this.state;
    return (
      <React.Fragment>
        <div className="header-bar">
          <header>
            <div className="left">
              <Star size={15} />
              <Star size={15} />
              <Star size={15} />
              <Star size={15} />
              <HalfStar size={15} />
              <p>
                {" "}
                Our customers <span>LOVE</span> what we do.{" "}
                <span>You will too!</span>
              </p>
            </div>
            <div className="right">
              <p>
                Print One <span> {">"} </span> Print One
                <span> Every order delivers positive change </span>
              </p>
            </div>
          </header>
          <div className="tab">
            <div className="logo">
              <img src={logo} alt="" />
            </div>
            <div className="tab-ul">
              <li onClick={() => this.routePage("home")}>HOME</li>
              <li onClick={() => this.routePage("about")}>ABOUT</li>
              <li onClick={() => this.routePage("privacy")}>PRIVACY</li>
            </div>
            <div className="cart" onClick={() => this.routePage("cart")}>
              <ShoppingCart size={25} />
              <div className="num">{cartArr.length}</div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default HeaderBar;
