import React from "react";
const Twtter = ({
  viewBox = "0 0 24 24",
  size = 24,
  color = "#fff",
  ...props
}) => (
  <svg
    viewBox="0 0 1024 1024"
    p-id="2311"
    width={size}
    height={size}
    {...props}
  >
    <path
      d="M665.376 382.592a143.104 143.104 0 0 0 47.072-16.736l11.456-6.592a97.984 97.984 0 0 1-29.824 45.568 47.04 47.04 0 0 1-10.112 7.552 158.08 158.08 0 0 0 52.032-14.72 152 152 0 0 1-31.168 38.08c-4.8 3.968-9.6 7.936-14.368 12.064a293.312 293.312 0 0 1-4.256 62.304c-22.944 108.864-83.776 182.848-180.064 214.56a260.48 260.48 0 0 1-130.048 5.632 328.896 328.896 0 0 1-54.016-18.656 215.904 215.904 0 0 1-26.016-14.144c-2.624-1.792-5.376-3.424-8.064-5.216 9.728 1.408 19.552 1.792 29.376 1.248 9.024-1.664 17.888-1.248 26.24-3.2a179.936 179.936 0 0 0 55.136-20.96c9.088-4.416 17.472-10.176 24.896-17.184-9.344 0-18.592-1.76-27.36-5.216a95.136 95.136 0 0 1-58.304-62.048c9.024 0.96 34.976 3.456 41.056-1.92a64.896 64.896 0 0 1-30.08-12.768c-27.744-17.504-44.288-49.248-43.264-83.04 3.136 1.504 6.272 3.168 9.408 4.672a89.6 89.6 0 0 0 19.296 5.632c4.032 1.44 8.32 1.856 12.576 1.216-5.088-5.632-12.64-9.472-17.28-15.52a103.424 103.424 0 0 1-20.64-88.128c2.336-8.8 5.632-17.28 9.888-25.248a73.6 73.6 0 0 0 8.736 10.432c8.064 10.24 17.28 19.52 27.36 27.584a265.216 265.216 0 0 0 112.32 57.376c13.504 3.584 27.36 5.504 41.28 5.632a95.2 95.2 0 0 1 0.448-45.568 94.432 94.432 0 0 1 52-65.056c6.592-2.88 13.44-5.152 20.416-6.72 3.744-0.416 7.488-0.832 11.2-1.376a91.84 91.84 0 0 1 72.64 30.464z"
      fill={color}
      p-id="2312"
    ></path>
    <path
      d="M512 1008C238.08 1008 16 785.92 16 512 16 238.08 238.08 16 512 16 785.92 16 1008 238.08 1008 512c0 273.92-222.08 496-496 496z m0-32c256.256 0 464-207.744 464-464S768.256 48 512 48 48 255.744 48 512 255.744 976 512 976z"
      fill={color}
      p-id="2313"
    ></path>
  </svg>
);

export default Twtter;
